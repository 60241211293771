import React, { useState } from "react"
import ClimateCheckLogo from "../../ui/ClimateCheckLogo/ClimateCheckLogo"
import ChevronIcon from "@images/icons/icon-chevron-right-solid.svg"

import SideBarInfor from "./SideBarInfo"

//Steps Views
import PropertyAddress from "./Steps/PropertyAddress"
import BillingInformation from "./Steps/BillingInformation"
import YourOrder from "./Steps/YourOrder"

const Stepper = ({ currentStep }) => {
  return (
    <div className="steps-container">
      <span className="step-billing active">
        Property Address
        <ChevronIcon className="icon-step-billing active-icon" />
      </span>
      <span className={`step-billing ${currentStep >= 1 ? "active" : null}`}>
        Billing Information
        <ChevronIcon
          className={`icon-step-billing ${
            currentStep > 0 ? "active-icon" : null
          }`}
        />
      </span>
      <span className={`step-billing ${currentStep === 2 ? "active" : null}`}>
        Your Order
      </span>
    </div>
  )
}

const GetPremium = ({ address }) => {
  const [currentStep, setStep] = useState(0)
  return (
    <main>
      <header className="premium-report-Header">
        <ClimateCheckLogo />
        <Stepper currentStep={currentStep} />
      </header>
      <div className="main-content">
        {currentStep === 0 ? (
          <PropertyAddress address={address} setStep={setStep} />
        ) : null}
        {currentStep === 1 ? (
          <BillingInformation address={address} setStep={setStep} />
        ) : null}
        {currentStep === 2 ? <YourOrder /> : null}
        <SideBarInfor />
      </div>
    </main>
  )
}

export default GetPremium
