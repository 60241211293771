import Strings from "./Strings"
import IntegrationClient from "./IntegrationClient"
import Utility from "./Utility"

export default class ClientServices {
  public strings: Strings
  public integrationClient: IntegrationClient
  public utility: Utility

  constructor() {
    this.strings = new Strings()
    this.integrationClient = new IntegrationClient()
    this.utility = new Utility()
  }
}
