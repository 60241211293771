type IpifyResponse = {
  ip: string;
};

export default class Utility {
  constructor() {}

  isDefinedWithContent(item) {
    if (typeof item !== "undefined" && item && item !== "" && item !== null) {
      if (item.constructor === Array && item.length > 0) {
        return true;
      } else if (item.constructor === Array && item.length === 0) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  getMin2dArray(arr) {
    let min = arr.length > 0 ? parseInt(arr[0]) : 0;
    arr.forEach((item, i) => {
      min = parseInt(item) < min ? parseInt(item) : min;
    });
    return min;
  }

  async getClientIPAddress(): Promise<IpifyResponse> {
    return fetch("https://api.ipify.org?format=json")
      .then((response) => {
        if (!response.ok) {
          return { ip: "" };
        }
        return response.json() as Promise<IpifyResponse>;
      })
      .catch((e) => {
        console.log("Error getting user ip", e);
        return { ip: "" };
      });
  }
}
