import React from "react"
import SEO from "@components/SEO"
import GetPremium from "@components/UnlockPremiumPage"
import "@styles/Components/unlockPremium.scss"
import { graphql } from "gatsby"
import Footer from "@components/state/Footer"

const UnluckPremiumPage = ({ location, data: { seo } }) => {
  let params = new URLSearchParams(location.search)
  const address = params.get("address")
  const customSeo = {
    title: seo?.seo?.title || "Unlock Premium",
    link: "https://climatecheck.com/unlock-premium",
    ...seo?.seo,
  }
  return (
    <>
      <SEO seo={customSeo} />
      <GetPremium address={address} />
      <Footer />
    </>
  )
}

export default UnluckPremiumPage

export const query = graphql`
  query UnlockPremium {
    seo: sanityPagesSeo(slug: { current: { eq: "/unlock-premium" } }) {
      seo {
        title
        keywords
        description
      }
    }
  }
`
