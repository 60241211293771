import Utility from "./Utility"
import fetch from "node-fetch"

export default class IntegrationClient {
  public utility = new Utility()

  constructor() {}

  mailchimpSubscribe(values: any, callback: any) {}

  async sendEmail(
    email: string,
    data: { email: string; description: string[] },
    subject: string,
    body: { email: string; description: string[]; propertyAddress: string }
  ) {
    const { constants } = require("../constants/constants")
    const { ip } = await this.utility.getClientIPAddress()

    if (this.utility.isDefinedWithContent(email)) {
      fetch(
        `${constants.API_URL}/sendMail?email=${email}&description=${data.description}&address=${body}&ip=${ip}`
      ).catch(error => {
        console.error("sendEmail ERROR: ", error)
      })
    } else {
      const err = "Sorry! No email provided. Code 4407"
      console.error(err)
      alert(err)
    }
  }

  getScoreSeverity(score: number): ScoreSeverity | null {
    let severity: ScoreSeverity | null = null
    if (score >  RelativelyLow.min && score < RelativelyLow.max) {
      severity = {
        key: SeverityKeys.relativelyLow,
        label: SeverityLabels.relativelyLow,
        description: SeverityDescriptions.relativelyLow,
      }
    } else if (score >= Significant.min && score < Significant.max) {
      severity = {
        key: SeverityKeys.Significant,
        label: SeverityLabels.significant,
        description: SeverityDescriptions.significant,
      }
    } else if (score >= HighRange.min && score < HighRange.max) {
      severity = {
        key: SeverityKeys.high,
        label: SeverityLabels.high,
        description: SeverityDescriptions.high,
      }
    } else if (score >= VeryHighRange.min && score < VeryHighRange.max) {
      severity = {
        key: SeverityKeys.veryHigh,
        label: SeverityLabels.veryHigh,
        description: SeverityDescriptions.veryHigh,
      }
    } else if (score >= ExtremeRange.min && score <= ExtremeRange.max) {
      severity = {
        key: SeverityKeys.extreme,
        label: SeverityLabels.extreme,
        description: SeverityDescriptions.extreme,
      }
    } else {
      severity = {
        key: SeverityKeys.more,
        label: SeverityLabels.more,
        description: SeverityDescriptions.more,
      }
    }
    return severity
  }
}
export interface ScoreSeverity {
  key: string
  label: string
  description: string
}

export interface SeverityInfo {
  keys: SeverityKeys
  labels: SeverityLabels
  descriptions: SeverityDescriptions
  ranges: SeverityRanges
}

export enum SeverityKeys {
  relativelyLow = "relativelyLow",
  Significant = "significant",
  high = "high",
  veryHigh = "veryHigh",
  extreme = "extreme",
  more = "more",
}

export enum SeverityLabels {
  relativelyLow = "Relatively Low",
  significant = "Significant",
  high = "High",
  veryHigh = "Very High",
  extreme = "Extreme",
  more = "None",
}

export enum SeverityDescriptions {
  relativelyLow = "",
  significant = "",
  high = "",
  veryHigh = "",
  extreme = "",
  more = "",
}

export interface SeverityRanges {
  relativelyLow: RelativelyLow
  significant: Significant
  high: HighRange
  veryHigh: VeryHighRange
  extreme: ExtremeRange
  more: MoreRange
}

export enum RelativelyLow {
  min = 0,
  max = 20,
}

export enum Significant {
  min = 20,
  max = 40,
}

export enum HighRange {
  min = 40,
  max = 60,
}

export enum VeryHighRange {
  min = 60,
  max = 80,
}

export enum ExtremeRange {
  min = 80,
  max = 100,
}

export enum MoreRange {
  min = 100,
  max = 100000,
}
