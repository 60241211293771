import React from "react"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"

const Footer = () => {
  return (
    <section className="state-footer">
      <div className="logo-footer-container">
        <div className="logo-footer">
          <ClimateCheckLogo />
        </div>
      </div>
    </section>
  )
}

export default Footer
