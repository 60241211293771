import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe(process.env.STRIPE_KEY);


const Form = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [errorForm, setError] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {


        if (!stripe || !elements) return;

        if (firstName.length === 0 || lastName.length === 0 || email.length === 0 || phone.length === 0 ||
            streetAddress.length === 0 || city.length === 0 || state.length === 0 || zip.length === 0) {
            return setError("All fields are required")
        }
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        const data = {
            firstName,
            lastName,
            email,
            phone,
            streetAddress,
            city,
            state,
            zip
        };

        if (error) return setError(error.message)
        else {
            console.log('[PaymentMethod]', paymentMethod);
        }
    }

    return (
        <div className="form-payment-container">
            <form>
                <h4>PERSONAL DETAILS</h4>
                <div className="double-input">
                    <div>
                        <input type="text" placeholder="First Name" onChange={e => setFirstName(e.currentTarget.value)} />
                    </div>
                    <div>
                        <input type="text" placeholder="Last Name" onChange={e => setLastName(e.currentTarget.value)} />
                    </div>
                </div>
                <div className="one-input">
                    <div>
                        <input type="email" placeholder="Email Address" onChange={e => setEmail(e.currentTarget.value)} />
                    </div>
                </div>
                <div className="one-input">
                    <div>
                        <input type="text" placeholder="Phone Number" onChange={e => setPhone(e.currentTarget.value)} />
                    </div>
                </div>


                <h4>BILLING ADDRESS</h4>
                <div className="one-input">
                    <div>
                        <input type="text" placeholder="Street Address" onChange={e => setStreetAddress(e.currentTarget.value)} />
                    </div>
                </div>
                <div className="one-input">
                    <div>
                        <input type="text" placeholder="City" onChange={e => setCity(e.currentTarget.value)} />
                    </div>
                </div>
                <div className="double-input">
                    <div>
                        <input type="text" placeholder="State" onChange={e => setState(e.currentTarget.value)} />
                    </div>
                    <div>
                        <input type="text" placeholder="Zip" onChange={e => setZip(e.currentTarget.value)} />
                    </div>
                </div>

                <h4>CREDIT CARD</h4>
                <div className="card-element">
                    <CardElement />
                </div>
                <div className="error" style={{ display: !errorForm ? 'None' : null }}>
                    *{errorForm}
                </div>
                <div className="gp-content-container">
                    <Button className="get-report-button goldSubmit" onClick={handleSubmit}  >
                        <span>Finalize &amp; Pay</span>
                    </Button>
                </div>
            </form>
        </div>
    );
}

const FormPayment = () => {

    return (

        <Elements stripe={stripePromise} >
            <Form />
        </Elements>
    );
}

export default FormPayment;

