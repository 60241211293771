import React from "react"
import Check from "@images/icons/Check_green_icon.png"
const SideBarInfor = () => {
  return (
    <div className="side-bar-info">
      <div className="side-bar-wrapper">
        <h3>IN YOUR REPORT</h3>
        <div className="list-item">
          <img src={Check}></img>
          <span>
            Environment risk assessment over the life of your investment.
          </span>
        </div>
        <div className="list-item">
          <img src={Check}></img>
          <span>Clarity on your exposure to the next catastrophic event.</span>
        </div>
        <div className="list-item">
          <img src={Check}></img>
          <span>
            Market vulnerability analysis to prepare for the next black swan.
          </span>
        </div>
      </div>
    </div>
  )
}

export default SideBarInfor
