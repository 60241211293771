export const constants = Object.freeze({
  API_URL: "https://api.climatecheck.com",
  APP_URL: "https://climatecheck.com",
  ACTIVECAMPAIGN_URL:
    "https://5gdebbq8sa.execute-api.us-east-1.amazonaws.com/deployment/store-user-info",
  MAPBOX_URL: "https://api.mapbox.com/geocoding/v5/mapbox.places",
  IP_API_URL: "https://pro.ip-api.com/json/",
  MAPBOX_KEY: process.env.MAPBOX_KEY,
  SANITY_PROJECTID: process.env.SANITY_PROJECTID,
  SANITY_DATASET: process.env.SANITY_DATASET,
  SANITY_TOKEN: process.env.SANITY_TOKEN,
  ACTIVECAMPAIGN_TOKEN: process.env.ACTIVECAMPAIGN_TOKEN,
  IP_API_KEY: process.env.IP_API_KEY,
})
