import React from "react"
import { Text, Button } from "@blueprintjs/core"
import ClientServices from "@services/ClientServices"
import Pin from "@images/img-min/pin.svg"

const PropertyAddress = ({ setStep, address }) => {
  const clientServices = new ClientServices()
  const displayAddress = clientServices.strings.getDisplayAddress(address)

  return (
    <div className="step-content">
      <Text className="title-step" tagName="h1">
        Unlock your Premium Report
      </Text>
      <Text className="text-step complete">
        Complete due dilligence for a one-time-cost of $499.
      </Text>
      <Text className="text-step confirm">
        First Confirm the address of the property you want to get the report
        for.
      </Text>
      <Text className="text-step property">PROPERTY ADDRESS</Text>
      <div className="gp-content-container">
        <div className="gp-content-address">
          <Pin />
          <Text className="text-address" tagName="span">
            {displayAddress}
          </Text>
        </div>
      </div>
      <div className="gp-content-container">
        <Button
          className="get-report-button goldSubmit"
          onClick={() => setStep(1)}
        >
          <span>Confirm to Continue</span>
        </Button>
      </div>
    </div>
  )
}

export default PropertyAddress
